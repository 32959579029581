/**
 * Direttiva che rende scrollable un menu/lista orizzontale (se c'è overflow) attraverso i pulsanti di navigazione ai lati di esso. Se richiesto, inoltre, è aggiunta la
 * possibilità di utilizzare lo swipe anche nel desktop. 
 * Alla direttiva è passata, oltre a quest'ultima possibilità, la distanza che deve percorrere al click sui pulsanti, l'id (dom) del contenitore (fratello dei pulsanti),
 * l'id (dom) del contenuto, e l'id (dom) dei pulsanti di navigazione. 
 * 
 * 
 * Esempio di utilizzo:
 * <div scrollable-menu add-drag="true" right-btn-id="nextFilter" left-btn-id="prevFilter" navbar-id="filtersNav" navbar-contents-id="filtersNavContents">
	    <div id="filtersNav">
		    <ul id="filtersNavContents">
			    <li>1</li>
		    	<li>2</li>
		    	<li>3</li>
            </ul>
        </div>
        <button class="scrollableMenuBtns scrollableMenuBtnLeft" id="prevFilter" type="button">
            <span class="scrollableMenuBtnIcon">< prev</span>
        </button>
        <button class="scrollableMenuBtns scrollableMenuBtnRight" id="nextFilter" type="button">
            <span class="scrollableMenuBtnIcon">next ></span>
        </button>
    </div>
 *
 * Da notare che è indifferente l'utilizzo dei tag, può essere inserito un <ul> come un <div>. L'importante è mantenere la struttura.
 * Le classi CSS necessarie al funzionamento sono aggiunte automaticamente (per questo è importante la non variazione della struttura), ad eccezione dei pulsanti. Essi non sono vincolati
 * affinché si possa cambiare il loro layout/css ogni volta che si utilizza il componente.
 * 
 */

angular.module('app').directive('scrollableHorizontalMenu', ['$window', ($window: any) => {
    return {
        restrict: "EA",
        replace: false,
        transclude: false,
        scope: {
            travelDistance: '@',
            addDrag: '@',
            rightBtnId: '@',
            leftBtnId: '@',
            navbarId: '@',
            navbarContentsId: '@'
        },
        link: function ($scope: any, element: any, attrs: any) {
            // Aggiungo la classe al contenitore
            element[0].classList.add("scrollableMenuWrapper");

            // Aggiungo la classe alla barra di navigazione
            let firstChild = element.children(':first');

            // Impostazioni generali
            let SETTINGS = {
                travelDirection: "",
                travelDistance: parseInt($scope.travelDistance) || 150
            }

            let isMoving = false;
            let scrollLeft = 0;

            // Aggiungo la classe al menu
            firstChild[0].classList.add("scrollableMenuNav");

            // Aggiungo la classe al contenuto
            let content = firstChild.children(':first');
            content[0].classList.add("scrollableMenuContents");

            // Metodo che calcola quanto il contenuto sfora dal contenitore, e in che direzione (dunque può sforare a destra, a sinistra, in entrambe le direzioni o da nessuna parte)
            let determineOverflow = function (content: any, container: any) {
                // Contenitore
                let containerMetrics = container.getBoundingClientRect();
                // Calcolo fino a dove, a destra, arriva il contenitore
                let containerMetricsRight = Math.floor(containerMetrics.right);
                // Calcolo fino a dove, a sinistra, arriva il contenitore
                let containerMetricsLeft = Math.floor(containerMetrics.left);
                // Contenuto
                let contentMetrics = content.getBoundingClientRect();
                // Calcolo fino a dove, a destra, arriva il contenuto
                let contentMetricsRight = Math.floor(contentMetrics.right);
                // Calcolo fino a dove, a sinistra, arriva il contenuto
                let contentMetricsLeft = Math.floor(contentMetrics.left);
                if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
                    // Se l'inizio del contenuto è più a sinistra del del contenitore (quindi contentMetricsLeft sarà un numero negativo) - e stessa cosa per la parte destra - allora l'overflow è da entrambe le parti
                    return "both";
                } else if (contentMetricsLeft < containerMetricsLeft) {
                    // Il contenuto sfora a sinistra
                    return "left";
                } else if (contentMetricsRight > containerMetricsRight) {
                    // Il contenuto sfora a destra
                    return "right";
                } else {
                    // Tutto il contenuto è visualizzato nel viewport corrente, e non c'è dunque overflow alcuno
                    return "none";
                }
            }

            // Recupero il riferimento al pulsante per la navigazione a sinistra
            let leftBtn = document.getElementById($scope.leftBtnId);

            // Recupero il riferimento al pulsante per la navigazione a destra
            let rightBtn = document.getElementById($scope.rightBtnId);

            // Contenitore della barra di navigazione
            let horizontalNav = document.getElementById($scope.navbarId);
            // Contenuto del contenitore della barra di navigazione
            let horizontalNavContent = document.getElementById($scope.navbarContentsId);

            // Setta alla barra di navigazione dove il suo contenuto sta sforando
            let setNewDataOverflowing = function () {
                let newOverflow = determineOverflow(horizontalNavContent, horizontalNav);
                horizontalNav.setAttribute("data-overflowing", newOverflow);
            }
            setNewDataOverflowing();

            // Se richiesto, aggiungo anche la possibilità di trascinamento con il mouse 
            if ($scope.addDrag && ($scope.addDrag == true || $scope.addDrag == "true")) {
                let isMousePressed = false;
                let x = 0;

                horizontalNav.addEventListener('mousedown', function (e: any) {
                    e.preventDefault();
                    isMousePressed = true;
                    scrollLeft = this.scrollLeft;
                    x = e.clientX;
                });

                horizontalNav.addEventListener('mouseup', function (e: any) {
                    e.preventDefault();
                    if (!isMoving) {
                        window.requestAnimationFrame(() => {
                            setNewDataOverflowing();
                            isMoving = false;
                        });
                    }
                    isMoving = true;
                    isMousePressed = false;
                });

                horizontalNav.addEventListener('mousemove', function (e: any) {
                    e.preventDefault();
                    if (isMousePressed) {
                        this.scrollLeft = scrollLeft + x - e.clientX;
                        if (!isMoving) {
                            window.requestAnimationFrame(() => {
                                setNewDataOverflowing();
                                isMoving = false;
                            });
                        }
                        isMoving = true;
                    }
                });

                horizontalNav.addEventListener('mouseleave', function (e: any) {
                    e.preventDefault();
                    if (!isMoving) {
                        window.requestAnimationFrame(() => {
                            setNewDataOverflowing();
                            isMoving = false;
                        });
                    }
                    isMoving = true;
                    isMousePressed = false;
                });
            }

            // Retrocede con gli elementi al click sul pulsante di sinistra
            if (leftBtn) {
                leftBtn.addEventListener("click", function () {
                    // Calcolo l'overflow attuale
                    let currentOverflow = determineOverflow(horizontalNavContent, horizontalNav);

                    // Se l'overflow è a sinistra oppure da entrambe le parti
                    if (currentOverflow === "left" || currentOverflow === "both") {
                        // Trovo di quanto è stato scrollato
                        let availableScrollLeft = horizontalNav.scrollLeft;
                        // Se non c'è abbastanza spazio per scrollare con la distanza di default, retrocedo solo quel tanto che basta per arrivare all'inizio della menu
                        if (availableScrollLeft < SETTINGS.travelDistance * 2) {
                            horizontalNavContent.style.transform = "translateX(" + availableScrollLeft + "px)";
                        } else {
                            horizontalNavContent.style.transform = "translateX(" + SETTINGS.travelDistance + "px)";
                        }
                        // Poichè vogliamo l'animazione (CSS) quando gli elementi si muovono, togliamo la classe CSS che la previene
                        horizontalNavContent.classList.remove("scrollableMenuContents-no-transition");
                        // Salvo la direzione in cui sto andando
                        SETTINGS.travelDirection = "left";
                    }
                });
            }

            // Avanza con gli elementi al click sul pulsante di destra
            if (rightBtn) {
                rightBtn.addEventListener("click", function () {
                    // Calcolo l'overflow attuale
                    let currentOverflow = determineOverflow(horizontalNavContent, horizontalNav);

                    // Se l'overflow è a destra oppure da entrambe le parti
                    if (currentOverflow === "right" || currentOverflow === "both") {
                        // Ricavo il bordo destro del contenitore e del contenuto
                        let navBarRightEdge = horizontalNavContent.getBoundingClientRect().right;
                        let navBarScrollerRightEdge = horizontalNav.getBoundingClientRect().right;

                        // Verifico quanto spazio ho ancora disponibile fino a raggiungere la fine
                        let availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);

                        // Se non c'è abbastanza spazio per scrollare con la distanza di default, avanzo solo quel tanto che basta per arrivare alla fine della menu
                        if (availableScrollRight < SETTINGS.travelDistance * 2) {
                            horizontalNavContent.style.transform = "translateX(-" + availableScrollRight + "px)";
                        } else {
                            horizontalNavContent.style.transform = "translateX(-" + SETTINGS.travelDistance + "px)";
                        }
                        // Poichè vogliamo l'animazione (CSS) quando gli elementi si muovono, togliamo la classe CSS che la previene
                        horizontalNavContent.classList.remove("scrollableMenuContents-no-transition");
                        // Salvo la direzione in cui sto andando
                        SETTINGS.travelDirection = "right";
                    }
                });
            }

            if (horizontalNavContent) {
                // Listener che si avvia quando la transizione degli elementi si è conclusa
                horizontalNavContent.addEventListener("transitionend", function () {
                    // Calcolo l'overflow attuale
                    let currentOverflow = determineOverflow(horizontalNavContent, horizontalNav);

                    // Ricavo il valore di quanto mi sono spostato, applicandolo alla posizione dello scroll corrente. Dunque ricavo la posizione dello scroll e poi rimuovo il transorm
                    let styleOfTransform = window.getComputedStyle(horizontalNavContent, null);
                    let transformationStyle = styleOfTransform.getPropertyValue("-webkit-transform") || styleOfTransform.getPropertyValue("transform");
                    let amount = Math.abs(parseInt(transformationStyle.split(",")[4]) || 0);

                    horizontalNavContent.style.transform = "none";
                    horizontalNavContent.classList.add("scrollableMenuContents-no-transition");

                    if (SETTINGS.travelDirection === "left") {
                        horizontalNav.scrollLeft = horizontalNav.scrollLeft - amount;
                    } else {
                        horizontalNav.scrollLeft = horizontalNav.scrollLeft + amount;
                    }
                    horizontalNav.setAttribute("data-overflowing", currentOverflow);
                }, false);
            }

            angular.element(document).ready(() => {
                if (!isMoving) {
                    window.requestAnimationFrame(() => {
                        setNewDataOverflowing();
                        isMoving = false;
                    });
                }
                isMoving = true;
            })

            angular.element($window).bind('resize', () => {
                if (!isMoving) {
                    window.requestAnimationFrame(() => {
                        setNewDataOverflowing();
                        isMoving = false;
                    });
                }
                isMoving = true;
            })
        }
    };
}])